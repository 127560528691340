import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useTranslation } from "react-i18next"

import config from '../../../content/meta/config';
import { getCurrentLang } from '../../utils/helpers';

const Seo = props => {
	const { t, i18n } = useTranslation();
	const { data, facebook } = props;
	const postTitle = ((data || {}).frontmatter || {}).title;
	const postDescription = ((data || {}).frontmatter || {}).description;
	const postCover = ((data || {}).frontmatter || {}).cover;
	const postSlug = ((data || {}).fields || {}).slug;

	const title = postTitle ? `${postTitle} - ${config.shortSiteTitle}` : t('site.title');
	const description = postDescription ? postDescription : t('site.description');
	const keywords = config.keywords;
	const image = postCover
		? config.siteUrl + config.pathPrefix + postCover.childImageSharp.resize.src
		: config.siteUrl + config.pathPrefix + '/' + config.siteImage;
	const url = postSlug
		? config.siteUrl + postSlug
		: typeof window !== 'undefined' ? config.siteUrl + window.location.pathname : config.siteUrl;

	return (
		<Helmet
			htmlAttributes={{
				lang: getCurrentLang(i18n.language),
				prefix: 'og: http://ogp.me/ns#'
			}}
		>
			{/* General tags */}
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
			{/* OpenGraph tags */}
			<meta property="og:url" content={url} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />
			<meta property="og:type" content="website" />
			<meta property="fb:app_id" content={facebook.appId} />
			{/* Twitter Card tags */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:creator" content={config.authorTwitterAccount ? config.authorTwitterAccount : ''} />
		</Helmet>
	);
};

Seo.propTypes = {
	data: PropTypes.object,
	facebook: PropTypes.object.isRequired
};

export default Seo;
